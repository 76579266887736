import { NavLink } from "react-router-dom";
import './SiteNavBar.css';
import { ClickAwayListener } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBars,
	faCalendarAlt,
	faCartShopping,
	faCheckToSlot,
	faGlobeEurope,
	faInfoCircle,
	faToolbox,
	faUsersCog,
	IconDefinition,
	faHand,
} from '@fortawesome/free-solid-svg-icons';
import { faSquareReddit } from '@fortawesome/free-brands-svg-icons'
import { useContext, useState } from "react";
import { AppStateContext } from "../AppState";
import { Role } from "../../../types";

function NavBarElement(props: { to: string, icon: IconDefinition, title: string, logged?: boolean, onClick: () => void }) {
	const appState = useContext(AppStateContext);
	const content = (
		<p>
			<FontAwesomeIcon icon={props.icon} />
			<span className='nav-text'>{props.title}</span>
		</p>
	);

	return (
		<li>
			{!props.logged || appState.logged ?
				<NavLink {...props}>{content}</NavLink> :
				<a href={"/login"}>{content}</a>
			}
		</li>
	);
}

export function SiteNavBar() {
	const appState = useContext(AppStateContext);
	const [open, setOpen] = useState(false);
	const isBDE = appState.logged && appState.user && appState.user.role >= Role.BDE;
	const isPREZ = appState.logged && appState.user && appState.user.role >= Role.PREZ;
	const isADMIN = appState.logged && appState.user && appState.user.role == Role.ADMIN;

	const handleClick = () => setOpen(false);

	const beginMenuItems = <>
		<NavBarElement to="/annonces" icon={faCalendarAlt} title="Annonces" onClick={handleClick} />
		<NavBarElement to="/assosclubs" icon={faGlobeEurope} title="Clubs & Associations" onClick={handleClick} />
		<NavBarElement to="/bde" icon={faInfoCircle} title="Le BDE" onClick={handleClick} />
		<NavBarElement to="/rec" icon={faHand} title="Le REC" onClick={handleClick} />
		<NavBarElement to="/shop" icon={faCartShopping} title="La Boutique" onClick={handleClick} />
		{/* appState.user && <NavBarElement to="/pixelwar" icon={faSquareReddit} title="Pixel War" onClick={handleClick} />*/}
		{/* <NavBarElement to="/elections" icon={faCheckToSlot} title="Élections BDE" onClick={handleClick} logged />*/}
	</>

	const endMenuItems = <>
		{isBDE && <NavBarElement to="/admin" icon={faToolbox} title="Admin" onClick={handleClick} />}
		{appState.user ? <NavBarElement to="/mon_compte" icon={faUsersCog} title="Mon Compte" onClick={handleClick} /> :
			<a href={"/login"}>
				<p>
					<FontAwesomeIcon icon={faUsersCog} />
					<span className='nav-text'>Connexion</span>
				</p>
			</a>
		}
	</>;

	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<nav className={`navbar${open ? ' open' : ''}`}>
				<NavLink className="navbar-brand" onClick={() => setOpen(false)} to="/" title="Retourner à l'accueil">
					<img alt="Brand" src="/img/logo-bde-blanc-min.png" />
				</NavLink>
				<div className="menu-container">
					<div className="menu">
						<ul className='navbar-begin-items'>
							{beginMenuItems}
						</ul>
						<ul className='navbar-end-items'>
							{endMenuItems}
						</ul>
					</div>
				</div>
				<div className='menu-button'>
					{isPREZ &&
						<NavLink className="navbar-scan" onClick={() => setOpen(false)} to="/scan" title="Scanner des qrcode">
							<img alt="Brand" src="/img/qrcode.png" />
						</NavLink>}
					<div className='menu-button' onClick={() => setOpen(!open)}>
						<FontAwesomeIcon icon={faBars} size="2xl" border />
					</div>
				</div>
			</nav>
		</ClickAwayListener>
	);
}
